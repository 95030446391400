import axios from 'axios';
import getSettings from '../../settings'
import { Bank, BankActivityStatistics, BankProvider, OpenBankingProviderInstitution, ScreenScrapingRedirectProviderInstitution } from '../../types/bank-types';
import {
    AddBankApiModel,
    BankToggleActiveApiModel,
    ListBankProvidersFilter,
    ListBanksFilter,
    MergeBanksApiModel,
    UpdateBankApiModel,
    UpdateBankWeightingsApiModel
} from './api-models';

const settings = getSettings();

// Base URLs
const root = `${settings.baseApiUrl}/admin/bank`;

export const relativeUrls = {
    // Generic Bank URLs
    list: '',
    add: '',
    update: (id: number) => `/${id}`,
    merge: `/merge`,
    getStatistics: '/statistics',

    // Screen Scraping (SC) URLs
    listProviders: '/provider',
    populateProviders: '/provider/populate-from-client',
    populateProvidersAndSync: '/provider/populate-from-client-and-sync',
    sync: '/provider/sync',
    toggleActive: (id: number) => `/${id}/toggle-active`,
    assignProvider: (bankId: number, providerId: number) => `/${bankId}/assign-provider/${providerId}`,

    // Open Banking (OB) URLs
    listObProviders: '/obprovider',
    populateObProviders: '/obprovider/populate-from-client',
    populateObProvidersAndSync: '/obprovider/populate-from-client-and-sync',
    obSync: '/obprovider/sync',
    toggleObActive: (id: number) => `/${id}/toggle-ob-active`,
    assignOpenBankingProvider: (bankId: number, providerId: number) => `/${bankId}/assign-open-banking-provider/${providerId}`,

    // Screen Scraping Redirect (SCR) URLs
    listScrProviders: '/scrprovider',
    populateScrProviders: '/scrprovider/populate-from-client',
    populateScrProvidersAndSync: '/scrprovider/populate-from-client-and-sync',
    scrSync: '/scrprovider/sync',
    toggleScrActive: (id: number) => `/${id}/toggle-scr-active`,
    assignScreenScrapingRedirectProvider: (bankId: number, providerId: number) => `/${bankId}/assign-screen-scraping-redirect-provider/${providerId}`,
};

// Generic Bank Operations
export const list = (filter?: ListBanksFilter) => {
    return axios.get<Bank[]>(`${root}${relativeUrls.list}`, { params: filter });
};

export const add = (model: AddBankApiModel) => {
    return axios.post<Bank>(`${root}${relativeUrls.add}`, model);
};

export const update = (id: number, model: UpdateBankApiModel) => {
    return axios.put<Bank>(`${root}${relativeUrls.update(id)}`, model);
};

export const merge = (model: MergeBanksApiModel) => {
    return axios.put<Bank>(`${root}${relativeUrls.merge}`, model);
};

export const getStatistics = () => {
    return axios.get<BankActivityStatistics>(`${root}${relativeUrls.getStatistics}`);
};

// Screen Scraping (SC) Operations
export const listProviders = (filter: ListBankProvidersFilter) => {
    return axios.get<BankProvider[]>(`${root}${relativeUrls.listProviders}`, { params: filter });
};

export const populateProviders = () => {
    return axios.post(`${root}${relativeUrls.populateProviders}`);
};

export const populateProvidersAndSync = () => {
    return axios.post(`${root}${relativeUrls.populateProvidersAndSync}`);
};

export const sync = () => {
    return axios.post(`${root}${relativeUrls.sync}`);
};

export const toggleActive = (id: number, active: boolean) => {
    const model: BankToggleActiveApiModel = { active };
    return axios.put<Bank>(`${root}${relativeUrls.toggleActive(id)}`, model);
};

export const assignProvider = (bankId: number, providerId: number) => {
    return axios.put<Bank>(`${root}${relativeUrls.assignProvider(bankId, providerId)}`);
};

// Open Banking (OB) Operations
export const listObProviders = (filter: ListBankProvidersFilter) => {
    return axios.get<OpenBankingProviderInstitution[]>(`${root}${relativeUrls.listObProviders}`, { params: filter });
};

export const populatObProviders = () => {
    return axios.post(`${root}${relativeUrls.populateObProviders}`);
};

export const populateObProvidersAndSync = () => {
    return axios.post(`${root}${relativeUrls.populateObProvidersAndSync}`);
};

export const addOb = (model: AddBankApiModel) => {
    return axios.post<Bank>(`${root}/ob`, model);
};

export const obSync = () => {
    return axios.post(`${root}${relativeUrls.obSync}`);
};

export const toggleObActive = (id: number, active: boolean) => {
    const model: BankToggleActiveApiModel = { active };
    return axios.put<Bank>(`${root}${relativeUrls.toggleObActive(id)}`, model);
};

export const assignOpenBankingProvider = (bankId: number, providerId: number) => {
    return axios.put<Bank>(`${root}${relativeUrls.assignOpenBankingProvider(bankId, providerId)}`);
};

export const updateProviderWeightings = (id: number, model: UpdateBankWeightingsApiModel) => {
    return axios.put<Bank>(`${root}${relativeUrls.update(id)}/weightings`, model);
};

// Screen Scraping Redirect (SCR) Operations
export const listScrProviders = (filter: ListBankProvidersFilter) => {
    return axios.get<ScreenScrapingRedirectProviderInstitution[]>(`${root}${relativeUrls.listScrProviders}`, { params: filter });
};

export const populateScrProviders = () => {
    return axios.post(`${root}${relativeUrls.populateScrProviders}`);
};

export const populateScrProvidersAndSync = () => {
    return axios.post(`${root}${relativeUrls.populateScrProvidersAndSync}`);
};

export const addScr = (model: AddBankApiModel) => {
    return axios.post<Bank>(`${root}/scr`, model);
};

export const scrSync = () => {
    return axios.post(`${root}${relativeUrls.scrSync}`);
};

export const toggleScrActive = (id: number, active: boolean) => {
    const model: BankToggleActiveApiModel = { active };
    return axios.put<Bank>(`${root}${relativeUrls.toggleScrActive(id)}`, model);
};

export const assignScreenScrapingRedirectProvider = (bankId: number, providerId: number) => {
    return axios.put<Bank>(`${root}${relativeUrls.assignScreenScrapingRedirectProvider(bankId, providerId)}`);
};
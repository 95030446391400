import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { BlockUi, FormValidator, FormikSideEffects, FormikAsyncSelect, Button, Form } from '../../../../shared';
import { nameOf } from '../../../../../helpers/object-helper';
import { required } from '../../../../shared/form/validators';
import { ScreenScrapingRedirectProviderInstitution, Bank } from '../../../../../types/bank-types';
import * as BankApi from '../../../../../api/bank';
import { OptionModel } from '../../../../../types/shared-types';
import WithApiHelper, { WithApiHelperProps } from '../../../../hoc/with-api-helper';

interface LinkToExistingBankFormProps extends WithApiHelperProps {
  provider: ScreenScrapingRedirectProviderInstitution
  onSubmitSuccess: () => void
  hideHeader?: boolean
  onBack?: () => void
  hideBack?: boolean
}

interface LinkToExistingBankFormValues {
  providerId: number
  bankId: number
}

const LinkToExistingBankForm: React.FC<LinkToExistingBankFormProps> = (props) => {

  const { provider, hideHeader, onBack, hideBack, onSubmitSuccess, apiHelper } = props;

  const validator = new FormValidator<LinkToExistingBankFormValues, LinkToExistingBankFormProps>({
    fields: [
      { name: 'providerId', validators: [required()] },
      { name: 'bankId', validators: [required()] },
    ]
  });

  const initialValues: Partial<LinkToExistingBankFormValues> = {
    providerId: provider.id,
  };

  const handleSearch = (search: string) => {
    return apiHelper.makeCall(() => BankApi.list({ nameContains: search, orderBy: nameOf<Bank>('name'), orderByDirection: 'a' }))
      .then(e => {
        const options = e.data.map(e => {
          const optionModel: OptionModel<number> = { label: e.name, value: e.id };
          return optionModel;
        });
        return options;
      });
  }

  const handleSubmit = (values: LinkToExistingBankFormValues, formikHelpers: FormikHelpers<LinkToExistingBankFormValues>) => {
    return apiHelper.makeCall(() => BankApi.assignScreenScrapingRedirectProvider(values.bankId, values.providerId))
      .then(e => onSubmitSuccess())
      .finally(() => formikHelpers.setSubmitting(false));
  }
  return (
    <Formik<LinkToExistingBankFormValues>
      initialValues={validator.getInitial(initialValues)}
      onSubmit={handleSubmit}
      render={(formikProps) => {
        return (
          <React.Fragment>
            {!hideHeader && <h4>{`Add bank for Provider`}</h4>}
            <Form className='link-to-bank-new-form' onSubmit={formikProps.handleSubmit}>
              <BlockUi blocking={formikProps.isSubmitting} text='Submitting...'>
                <FormikAsyncSelect
                  label='Bank'
                  placeholder='Search for bank...'
                  searchPromise={handleSearch}
                  formikProps={formikProps}
                  name={nameOf<LinkToExistingBankFormValues>('bankId')}
                />
                <Button className='w-100 mb-2' type='submit' bsVariant='primary'>Link to Existing Bank</Button>
                {!hideBack && onBack && <Button className='w-100' type='button' bsVariant='secondary' onClick={onBack}>Back</Button>}
              </BlockUi>
              <FormikSideEffects focusInputOnSubmitFail />
            </Form>
          </React.Fragment>
        )
      }}
    />
  );
}

export default WithApiHelper(LinkToExistingBankForm);

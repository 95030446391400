import { Bank } from "../types/bank-types";

export const getProviderTypesFromBank = (bank: Bank) => {
  return bank?.providers?.map(e => e.type) ?? [];
}

export const getObProviderTypesFromBank = (bank: Bank) => {
  return bank?.obProviders?.map(e => e.type) ?? [];
}

export const getScrProviderTypesFromBank = (bank: Bank) => {
  return bank?.scrProviders?.map(e => e.type) ?? [];
}
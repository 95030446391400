import React from 'react';
import { RouteComponentProps } from 'react-router';

import { TabPages } from '../../shared';
import Activity from './activity';
import Banks from './banks';
import UnmappedProviders from './unmapped-providers';
import UnmappedObProviders from "./unmapped-ob-providers";
import UnmappedScrProviders from "./unmapped-scr-providers";
import WordCategorisation from './word-categorisation';
import WithAdminUser, { withAdminUserProps } from '../../hoc/with-admin-user';
interface BankStatementProps extends RouteComponentProps, withAdminUserProps {
}

const BankStatement: React.FC<BankStatementProps> = (props) => {

  const { match: { path } } = props;

  return (
    <TabPages
      redirectFromBase
      tabs={[
        { label: 'Activity', link: `${path}/activity`, component: Activity, exact: true },
        { label: 'Banks', link: `${path}/banks`, component: Banks, exact: true },
        { label: 'Unmapped SC Providers', link: `${path}/unmapped-providers`, component: UnmappedProviders, exact: true },
        { label: 'Unmapped SCR Providers', link: `${path}/unmapped-scr-providers`, component: UnmappedScrProviders, exact: true },
        { label: 'Unmapped OB Providers', link: `${path}/unmapped-ob-providers`, component: UnmappedObProviders, exact: true },
        { label: 'Word Categorisation', link: `${path}/word-categorisation`, component: WordCategorisation },
      ]}
    />
  );
}

export default WithAdminUser(BankStatement);

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { faToggleOff, faToggleOn, faSync, faEdit, faObjectGroup, faBalanceScale } from '@fortawesome/free-solid-svg-icons';

import { DataTable, ChipList } from '../../../shared';
import { Bank } from '../../../../types/bank-types';
import * as BankApi from '../../../../api/bank';
import { toChipProps } from '../../../shared/chips/chip';
import { DataTableToolBarButton } from '../../../shared/table/data-table/components/data-table-toolbar';
import UpdateBankModal from './update-bank/update-bank-modal';
import MergeBanksModal from './merge-banks/merge-banks-modal';
import UpdateBankWeightingsModal from './update-bank-weightings/update-bank-weightings-modal';
import * as BankHelper from '../../../../helpers/bank-helper';
import DashboardPage from '../../../hoc/dashboard-page';
import { DataTableAction } from '../../../shared/table/data-table-actions';
import WithApiHelper, { WithApiHelperProps } from '../../../hoc/with-api-helper';
import RenderHelper from '../../../shared/table/data-table/helpers/data-table-render-helper';

interface BanksPageModalProps {
  type: 'update' | 'merge' | 'updateWeighting'
  open: boolean
  bank1?: Bank
  bank2?: Bank
}

interface BanksState {
  banks: Bank[]
  reloading?: boolean
  loadingMessage?: string
  modal: BanksPageModalProps
}

interface BanksProps extends RouteComponentProps, WithApiHelperProps {
}

const Banks: React.FC<BanksProps> = ({ apiHelper, toastHelper }) => {

  const [state, setState] = useState<BanksState>({
    banks: [],
    reloading: true,
    loadingMessage: undefined,
    modal: { type: 'update', open: false }
  });

  apiHelper.setDefaultOnErrorAction(() => setState(ps => ({ ...ps, loadingMessage: undefined, reloading: false })));

  //Reload actions
  const reload = () => {
    return apiHelper.makeCall(() => BankApi.list(), { skipDefaultOnErrorAction: true })
      .then(response => Promise.resolve(response.data));
  }

  const reloadAndSetState = () => {
    setState((ps) => ({ ...ps, reloading: true }));
    reload().then(banks => setState((ps) => ({ ...ps, reloading: false, banks: banks })))
  }

  //On Start
  useEffect(() => {
    reload()
      .then((e) => setState((ps) => ({ ...ps, reloading: false, banks: e })))
  }, []);

  const getRowActions = (bank: Bank) => {
    const actions: DataTableAction<any>[] =
      [{
        iconFunc: (d) => d.openBankingSupported ? faToggleOn : faToggleOff,
        onClick: handleToggleObEnabled,
        variant: 'transparent',
        iconStyleFunc: (d) => d.openBankingSupported ? { color: 'green' } : { color: 'red' },
        tooltipFunc: (d) => d.openBankingSupported ? 'Disable Open Banking' : 'Enable Open Banking',
        disabled: bank.obProviders ? bank.obProviders.every(provider => provider.inactive) : true,
        text: "Toggle OB"
      },
      {
        iconFunc: (d) => d.screenScrapingRedirectSupported ? faToggleOn : faToggleOff,
        onClick: handleToggleScrEnabled,
        variant: 'transparent',
        iconStyleFunc: (d) => d.screenScrapingRedirectSupported ? { color: 'green' } : { color: 'red' },
        tooltipFunc: (d: Bank) =>  d.inactive ? 'SC must be enabled for this bank as SCR is intended only to be a backup' 
        : d.screenScrapingRedirectSupported ? 'Disable Screen Scraping Redirect' : 'Enable Screen Scraping Redirect',
        disabled: bank.scrProviders ? bank.scrProviders.every(provider => provider.inactive) : true,
        text: "Toggle SCR"
      },
      {
        iconFunc: (d) => d.inactive ? faToggleOff : faToggleOn,
        onClick: handleToggleInActive,
        variant: 'transparent',
        iconStyleFunc: (d) => d.inactive ? { color: 'red' } : { color: 'green' },
        tooltipFunc: (d) => d.inactive ? 'Enable Screen Scraping' : 'Disable Screen Scrapinge',
        disabled: bank.providers ? bank.providers.every(provider => provider.inactive) : true,
        text: "Toggle SC"
      },
      {
        icon: faEdit,
        variant: 'transparent',
        onClick: (d) => setState((ps) => ({ ...ps, modal: { open: true, bank1: d, type: 'update' } })),
        tooltip: 'Update'
      }];

    if (bank.providers && bank.providers.length > 1) {
      actions.push({
        icon: faBalanceScale,
        variant: 'transparent',
        onClick: (d) => setState((ps) => ({ ...ps, modal: { open: true, bank1: d, type: 'updateWeighting' } })),
        tooltip: 'Update Provider Weightings'
      });
    }

    return actions;
  }
  const handleToggleInActive = (bank: Bank) => {
    const active = bank.inactive;

    if (!active && bank.screenScrapingRedirectSupported) {
      toastHelper.error('SCR can not be active without SC. Please disable SCR first');
      return;
    }

    setState((ps) => ({ ...ps, loadingMessage: `Setting Bank ${active ? 'active' : 'inactive'}...` }));
    apiHelper.makeCall(() => BankApi.toggleActive(bank.id, active))
      .then((e) => {
        toastHelper.success(`${bank.name} successfully updated`)
        setState((ps) => {
          const newState = { ...ps, loadingMessage: undefined };
          const index = newState.banks.findIndex(e => e.id === bank.id);
          if (index >= 0) newState.banks[index] = e.data;
          return newState;
        });
      });
  }

  const handleToggleObEnabled = (bank: Bank) => {
    const active = !bank.openBankingSupported;

    setState((ps) => ({ ...ps, loadingMessage: `${active ? 'Enabling' : 'Disabling'} Open Banking for ${bank.name}...` }));
    apiHelper.makeCall(() => BankApi.toggleObActive(bank.id, active))
        .then((e) => {
          toastHelper.success(`${bank.name} successfully updated`)
          setState((ps) => {
            const newState = { ...ps, loadingMessage: undefined };
            const index = newState.banks.findIndex(e => e.id === bank.id);
            if (index >= 0) newState.banks[index] = e.data;
            return newState;
          });
        });
  }

  const handleToggleScrEnabled = (bank: Bank) => {
    if (bank.inactive && !bank.screenScrapingRedirectSupported) {
      toastHelper.error('SC must be enabled for this bank as SCR is intended only to be a backup');
      return;
    }
    const active = !bank.screenScrapingRedirectSupported;
    setState((ps) => ({ ...ps, loadingMessage: `${active ? 'Enabling' : 'Disabling'} Screen Scraping Redirect for ${bank.name}...` }));
    apiHelper.makeCall(() => BankApi.toggleScrActive(bank.id, active))
        .then((e) => {
          toastHelper.success(`${bank.name} successfully updated`)
          setState((ps) => {
            const newState = { ...ps, loadingMessage: undefined };
            const index = newState.banks.findIndex(e => e.id === bank.id);
            if (index >= 0) newState.banks[index] = e.data;
            return newState;
          });
        });
  }

  const handleMergeBanksClick = (ids: number[]) => {
    const banks = state.banks.filter(e => ids.includes(e.id));
    const error = getCanBanksMergeErrorFromBanks(banks)
    if (error) {
      toastHelper.error(error);
      return;
    }
    setState((ps) => ({ ...ps, modal: { open: true, bank1: banks[0], bank2: banks[1], type: 'merge' } }));
  }

  const handleUpdateSuccess = (updatedBank: Bank) => {
    toastHelper.success(`${updatedBank.name} successfully updated`);
    setState(ps => {
      const newState = { ...ps };
      newState.modal.open = false;
      const index = newState.banks.findIndex(e => e.id === updatedBank.id);
      if (index >= 0) newState.banks[index] = updatedBank;
      return newState;
    });
  }

  const getCanBanksMergeError = (bankIds: any[]) => getCanBanksMergeErrorFromBanks(state.banks.filter(e => bankIds.includes(e.id)));

  const getCanBanksMergeErrorFromBanks = (banks: Bank[]) => {
    const baseError = 'Cannot merge banks - '
    if (banks.length !== 2) return `${baseError}can only merge 2 banks`;

    const providersFromFirst = !banks[0].providers ? [] : banks[0].providers.map(e => e.type);
    const obProvidersFromFirst = !banks[0].obProviders ? [] : banks[0].obProviders.map(e => e.type);
    const providersFromSecond = !banks[1].providers ? [] : banks[1].providers.map(e => e.type);
    const obProvidersFromSecond = !banks[1].obProviders ? [] : banks[1].obProviders.map(e => e.type);

    if ((!providersFromFirst.length && !obProvidersFromFirst.length) || (!providersFromSecond.length && !obProvidersFromSecond.length)) {
      return `${baseError}both banks must be linked to at least one provider`;
    }

    if ((providersFromFirst.some(e => providersFromSecond.includes(e))) || (obProvidersFromFirst.some(e => obProvidersFromSecond.includes(e)))) {
      return `${baseError}banks cannot have any shared provider`;
    }

    return undefined;
  }

  const handleMergeBanksSuccess = (mergedBank: Bank, removedBankId: number) => {
    toastHelper.success(`${mergedBank.name} successfully merged`);
    setState(ps => {
      const newState = { ...ps };
      newState.modal.open = false;
      const mergedBankIndex = newState.banks.findIndex(e => e.id === mergedBank.id);
      if (mergedBankIndex >= 0) newState.banks[mergedBankIndex] = mergedBank;
      const removedIndex = newState.banks.findIndex(e => e.id === removedBankId);
      if (removedIndex >= 0) newState.banks.splice(removedIndex, 1);
      return newState;
    });
  }

  const resolveSelectedToolbarActions = (selectedIds: any[]) => {
    const actions: DataTableToolBarButton[] = [];
    if (selectedIds && selectedIds.length === 2) {
      const error = getCanBanksMergeError(selectedIds);
      actions.push({ key: 'merge', icon: faObjectGroup, disabled: error ? true : false, toolTip: error || 'Merge Banks', onClick: () => handleMergeBanksClick(selectedIds) });
    }
    return actions;
  }

  return (
    <React.Fragment>
      <DataTable
        loading={state.reloading}
        blocking={state.loadingMessage !== undefined}
        blockingMessage={state.loadingMessage}
        selectable
        pageOptions={{ itemsPerPage: 10 }}
        toolbarProps={{
          actionButtons: [
            { key: 'reload', icon: faSync, toolTip: 'Reload', onClick: () => reloadAndSetState() }
          ],
          selectedActionButtons: resolveSelectedToolbarActions
        }}
        data={!state.banks.length ? [] : [...state.banks]}
        dataIdProperty='id'
        columns={[
          { key: 'id', header: 'Id' },
          { key: 'name', header: 'Name', searchable: true, filters: [{ type: 'StringContains' }] },
          { key: 'providers', header: 'SC Providers', searchable: true, filters: [{ type: 'StringContains' }], compareValue: (r) => BankHelper.getProviderTypesFromBank(r).join(' '), renderCell: (r) => <ChipList chips={toChipProps(r.providers, (r => r.type))} /> },
          { key: 'scrProviders', header: 'SCR Providers', searchable: true, filters: [{ type: 'StringContains' }], compareValue: (r) => BankHelper.getScrProviderTypesFromBank(r).join(' '), renderCell: (r) => <ChipList chips={toChipProps(r.scrProviders, (r => r.type))} /> },
          { key: 'obProviders', header: 'OB Providers', searchable: true, filters: [{ type: 'StringContains' }], compareValue: (r) => BankHelper.getObProviderTypesFromBank(r).join(' '), renderCell: (r) => <ChipList chips={toChipProps(r.obProviders, (r => r.type))} /> },
          { key: 'aliases', header: 'Aliases', searchable: true, filters: [{ type: 'StringContains' }], compareValue: (r) => r.aliases?.join(' '), renderCell: (r) => <ChipList chips={toChipProps(r.aliases)} /> },
          { key: 'openBankingactive' as any, header: 'OB Active', searchable: true, compareValue: (r) => r.openBankingSupported ? true : false, renderCell: (r) => RenderHelper.booleanByValue(r.openBankingSupported) },
          { key: 'screenScrapingRedirectActive' as any, header: 'SCR Active', searchable: true, compareValue: (r) => r.screenScrapingRedirectSupported ? true : false, renderCell: (r) => RenderHelper.booleanByValue(r.screenScrapingRedirectSupported) },
          { key: 'active' as any, header: 'SC Active', searchable: true, compareValue: (r) => !r.inactive ? true : false, renderCell: (r) => RenderHelper.booleanByValue(!r.inactive) }
        ]}
        actionsFunc={getRowActions}
      />
      <UpdateBankModal
        open={state.modal.type === 'update' && state.modal.open}
        onSubmitSuccess={handleUpdateSuccess}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
        bank={state.modal.bank1!}
      />
      <MergeBanksModal
        open={state.modal.type === 'merge' && state.modal.open}
        onSubmitSuccess={handleMergeBanksSuccess}
        bank1={state.modal.bank1!}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
        bank2={state.modal.bank2!}
      />
      <UpdateBankWeightingsModal
        open={state.modal.type === 'updateWeighting' && state.modal.open}
        onSubmitSuccess={handleUpdateSuccess}
        bank={state.modal.bank1!}
        onClose={() => setState((ps) => ({ ...ps, modal: { ...ps.modal, open: false } }))}
      />

    </React.Fragment>
  );
}

export default DashboardPage(WithApiHelper(Banks));

import React from 'react';

import { FormDisplay, Tabs } from '../../../../shared';
import { ScreenScrapingRedirectProviderInstitution } from '../../../../../types/bank-types';
import LinkToBankNewForm from './link-to-new-bank-form';
import LinkToExistingBankForm from './link-to-existing-bank-form';

interface LinkToBankContainerProps {
  provider: ScreenScrapingRedirectProviderInstitution
  onSubmitSuccess: () => void
  onBack: () => void
  hideHeader?: boolean
}

const LinkToBankContainer: React.FC<LinkToBankContainerProps> = (props) => {

  const { provider, onSubmitSuccess, hideHeader, onBack } = props;

  return (
    <React.Fragment>
      {!hideHeader && <h4>{`Link Provider to Bank`}</h4>}
      <FormDisplay label='Provider' text={`${provider.name} - ${provider.type}`} />
      <hr />
      <div>
        <Tabs variant='full-width' tabs={[
          { label: 'Existing', component: <LinkToExistingBankForm hideHeader onBack={onBack} onSubmitSuccess={onSubmitSuccess} provider={provider} /> },
          { label: 'Add New', component: <LinkToBankNewForm onBack={onBack} onSubmitSuccess={onSubmitSuccess} provider={provider} /> }]} />
      </div>
    </React.Fragment>
  );
}

export default LinkToBankContainer;
